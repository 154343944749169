<template>
  <div>
    <!--  -->
    <div class="RightSidebar">
      <div class="SidebarBox" @click="kefu">
        <div class="SidebarBox_ioc" >
             <img class="sidebarBox_img" src="@/assets/page/zixun.png" alt="">
        </div>
        <div class="SidebarBox_text">在线客服</div>
      </div>
      <div class="Split_line"></div>
      <div class="SidebarBox">
        <div class="SidebarBox_ioc">
             <img class="sidebarBox_img" src="@/assets/page/zixun.png" alt="">
        </div>
        <div class="SidebarBox_text">小程序</div>
      </div>
      <div class="Split_line"></div>
      <div class="SidebarBox">
        <div class="SidebarBox_ioc">
             <img class="sidebarBox_img" src="@/assets/page/zixun.png" alt="">
        </div>
        <div class="SidebarBox_text">咨询电话</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
    methods: {
        kefu() {
        // console.log(123);
    }
  },
};
</script>

<style type="text/css" scoped>
.RightSidebar {
  width: 60px;
  height: 302px;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 40%;
  box-shadow: 0px 1px 4px rgba(0,0,0,0.3),
0px 0px 20px rgba(0,0,0,0.1) ;
}
.SidebarBox {
  height: 80px;
  background-color: #fff;
  margin-top: 4px;
  cursor:pointer;
}
.SidebarBox_ioc {
  width: 32px;
  height: 32px;
  background-color: #fff;
  margin: 0 auto;
}
.SidebarBox_text {
  width: 33px;
  height: 40px;
  background-color: #fff;
  font-size: 12px;
  margin: 4px auto;
}
.sidebarBox_img{
    width: 24px;
    height: 24px;
    padding: 0px 0px 0px 3px ;
}
/* 分割线 */
.Split_line{
    width: 26px;
    margin: 4px  auto 12px;
    border: 1px solid #dddddd;
}
</style>
