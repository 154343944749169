
export default {
    // 验证手机号
     phoneumber(e) {
        let box =/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        return box.test(e)
    },
    // 验证密码
    password(e) {
        var pattern = /^(?=[a-zA-z]+)(?=.*\d+.*)(?=.*[a-z|A-Z]+.*)(?=.*_.*)[\d(a-z|A-Z)_]{6,10}$/
        console.log(pattern.test(e));    
    }
}
