<template>
  <div class="page" :style="`${contentWidth}`">
    <!-- 1 -->
    <div class="topzhanwei"></div>
    <div class="sum">
      <div class="content">
        <!-- logo -->
        <div class="logo_box">
          <div class="tab_logo animate__animated animate__jello">
            <img src="@/assets/design-img/logo.png" alt="" />
          </div>
          <!-- 竖杠 -->
          <div class="vertical"></div>
          <!-- 文字 -->
          <div class="tab_text">
            <h2>威设计</h2>
            <div style="font-size: 12px; margin-top: 3px; color: #666666">
              一站式鞋类设计研发示范平台
            </div>
          </div>
          <!-- 搜索框
        <div class="inp_box">
          <div class="inp_box_left">
            <select class="inp_select" size="1">
              <option value="bj">北京</option>
              <option value="sh" selected>上海</option>
              <option value="gz">广州</option>
              <option value="sz">深圳</option>
            </select>
            <div class="inp_nav">
              <img src="@/assets/page/fangdajing_1.png" alt="" />
              <input type="text" placeholder="请输入任务" v-model="SearchTasks" />
            </div>
          </div>
          <div class="inp_box_right">搜索</div>
        </div> -->
        </div>
        <!-- 右侧 -->
        <div class="right_box">
          <ul class="Navigation">
            <li>
              <span :class="{ active: isH === 'all' }" @click="page('all')"
                >首页</span
              >
            </li>
            <li>
              <span :class="{ active: isH === 'sj' }" @click="Design('sj')"
                >设计</span
              >
            </li>
            <li>
              <span :class="{ active: isH === 'kf' }" @click="development('kf')"
                >开发</span
              >
            </li>
            <li>
              <span :class="{ active: isH === 'zx' }" @click="info('zx')"
                >资讯</span
              >
            </li>
          </ul>
          <!-- 登录 -->
          <div class="login_box">
            <!-- 未登录的时候 -->
            <div class="deng" v-if="!tokens">
              <button class="login_box_SignIn" @click="SignIn">登录</button>
              <button class="login_box_register" @click="maxaddregister">
                注册
              </button>
            </div>
            <!-- 已登录的时候 -->
            <div class="Loggedin" v-else>
              <div class="login_box_img">
                <img
                  @click="personal('geren')"
                  :src="this.$store.state.currDbSource.length == 0
                    ? LoginSucceeded.avatar
                    : this.$store.state.currDbSource.avatar" alt=""/>
              </div>
              <div class="login_box_text" @click="personal('geren')">
                {{
                  this.$store.state.currDbSource.length == 0
                    ? LoginSucceeded.nick_name
                    : this.$store.state.currDbSource.nick_name
                }}
              </div>
              <div class="ling_box" @click="ling">
                <img class="ling" src="@/assets/page/ling.png" alt="" />
              </div>
              <div class="login_box_signOut">
                <img @click="signout" src="@/assets/page/组 2269.png" alt="" />
              </div>
            </div>

            <!-- 弹框 -->
            <div class="SpringFrame" v-if="dialogVisible">
              <!-- 登录 -->
              <div v-if="loginAndregister == 1" class="SpringFrame_nav">
                <!-- login_top -->
                <div class="login_top">
                  <div class="SpringFrame_logo">
                    <img src="@/assets/page/组 3010.png" alt="" />
                    <div class="SpringFrame_shu"></div>
                    <div class="SpringFrame_text">威设计</div>
                  </div>
                  <img
                    @click="close"
                    class="delete_img"
                    src="@/assets/page/cuo.png"
                    alt=""
                  />
                  <div class="PasswordLogin_box">
                    <div
                      class="PasswordLogin"
                      v-for="(item, index) in ContainerArray"
                      :key="index"
                      @click="addpasswoed(index)"
                      :class="{ actives: passwoedindex == index }"
                    >
                      {{ item }}
                    </div>
                    <div class="SpringFrame_shu2"></div>
                    <!-- <div class="Verification">验证码登录</div> -->
                  </div>
                </div>
                <!-- 登录 -->
                <div v-if="passwoedindex == 0">
                  <!-- inp -->
                  <div class="inp_boxs">
                    <el-input
                      type="text"
                      class="inp_sj"
                      v-model="PasswordLogin.phone"
                      placeholder="手机号"
                    />
                  </div>
                  <div class="inp_boxs2">
                    <el-input
                      :type="Judgingeyes"
                      class="inp_sj2"
                      v-model="PasswordLogin.password"
                      placeholder="请输入密码"
                    />
                    <img
                      :style="!passwordEye ? 'width: 18px;height: 13px;' : ''"
                      @click="Oneye"
                      class="password_img"
                      :src="Highlight"
                      alt=""
                    />
                  </div>

                  <button class="determine_box" @click="logOn">登录</button>
                  <div class="register_box">
                    <div class="register" @click="addregister">注册</div>
                    <div class="forget" @click="forgetClick">忘记密码?</div>
                  </div>
                  <div class="agreement_box">
                    <el-checkbox
                      v-model="PasswordLogin.checked"
                      class="login_check"
                    ></el-checkbox>
                    <div class="agreement">
                      同意<span class="xieyi" @click="Agreementpage(agreementData[0].id)"
                        >《用户协议》</span
                      >及<span class="xieyi" @click="Agreementpage(agreementData[1].id)">《隐私政策》</span>
                    </div>
                  </div>
                </div>
                <!-- 验证码登录 -->
                <div v-else-if="passwoedindex == 1">
                  <div class="inp_boxs">
                    <input
                      type="text"
                      class="inp_sj"
                      v-model="VerificationCode.Verificationphone"
                      placeholder="手机号"
                    />
                  </div>
                  <div class="Verification_box_inp">
                    <input
                      type="text"
                      class="inp_verif2"
                      v-model="VerificationCode.passwordphone"
                      placeholder="请输入验证码"
                    />
                    <button
                      class="Verification_box_but"
                      @click="send"
                      v-if="loginzx.isSend"
                    >
                      {{ loginzx.isname }}
                    </button>
                    <button
                      class="Verification_box_but2"
                      v-if="!loginzx.isSend"
                    >
                      {{ loginzx.sendmsg }}
                    </button>
                  </div>
                  <button class="determine2_box" @click="loginCode">
                    登录
                  </button>
                  <div class="agreement_box">
                    <el-checkbox
                      class="login_check"
                      v-model="VerificationCode.checked"
                    ></el-checkbox>
                    <div class="agreement2">
                      同意<span class="xieyi" @click="Agreementpage(agreementData[0].id)"
                        >《用户协议》</span
                      >及<span class="xieyi" @click="Agreementpage(agreementData[1].id)">《隐私政策》</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 注册 -->
              <div class="SpringFrame_nav" v-if="loginAndregister == 0">
                <div class="register_top">注册</div>
                <img
                  @click="close"
                  class="delete_img2"
                  src="@/assets/page/cuo.png"
                  alt=""
                />
                <div class="register_shu"></div>
                <div class="inp_boxs22">
                  <el-input
                    type="text"
                    class="inp_sj"
                    v-model="register.registerName"
                    placeholder="起一个好听的昵称"
                  />
                </div>
                <div class="inp_boxs3">
                  <el-input
                    type="text"
                    class="inp_sj"
                    v-model="register.registerPhone"
                    placeholder="手机号"
                  />
                </div>
                <div class="Verification_box_inp2">
                  <el-input
                    type="text"
                    class="inp_verif"
                    v-model="register.registerVerification"
                    placeholder="请输入验证码"
                  />
                  <button
                    class="Verification_box_but"
                    @click="zcsend"
                    v-if="establish.isSend"
                  >
                    {{ establish.isname }}
                  </button>
                  <button
                    class="Verification_box_but2"
                    v-if="!establish.isSend"
                  >
                    {{ establish.sendmsg }}
                  </button>
                </div>
                <!-- 注册密码 -->
                <div class="registerPassword">
                  <el-input
                    :type="Judgingeyes2"
                    class="inp_sj2"
                    v-model="register.registerPassword"
                    placeholder="请输入密码"
                    @blur="zcpassword"
                  />
                  <img
                    :style="!passwordEye2 ? 'width: 18px;height: 13px;' : ''"
                    @click="Oneye2"
                    class="password_img"
                    :src="Highlight2"
                    alt=""
                  />
                </div>
                <div class="registerPassword2">
                  <el-input
                    :type="Judgingeyes3"
                    class="inp_sj2"
                    v-model="register.Confirmpassword"
                    placeholder="请再次输入密码"
                    @blur="confirmpassword"
                  />
                  <img
                    :style="!passwordEye3 ? 'width: 18px;height: 13px;' : ''"
                    @click="Oneye3"
                    class="password_img"
                    :src="Highlight3"
                    alt=""
                  />
                </div>
                <button class="determine2_box2" @click="tabregister">
                  注册
                </button>
                <div class="agreement_box2">
                  <el-checkbox
                    class="login_check"
                    v-model="register.checked"
                  ></el-checkbox>
                  <div class="agreement2">
                    同意<span class="xieyi2" @click="Agreementpage(agreementData[0].id)"
                      >《用户协议》</span
                    >及<span class="xieyi" @click="Agreementpage(agreementData[1].id)">《隐私政策》</span>
                  </div>
                </div>
                <div class="have">
                  已有账号？去<span @click="Jumplogin">登录</span>
                </div>
              </div>

              <!-- 忘记密码 -->
              <div class="forgets" v-if="loginAndregister == 2">
                <div class="header">
                  忘记密码
                  <img
                    @click="dialogVisible = !dialogVisible"
                    class="f_close"
                    src="@/assets/page/cuo.png"
                  />
                </div>
                <div class="contents">
                  <div class="item">
                    <input
                      v-model="forgetParams.phone"
                      type="text"
                      placeholder="手机号"
                    />
                  </div>
                  <div class="item">
                    <input
                      v-model="forgetParams.code"
                      type="text"
                      placeholder="请输入验证码"
                    />
                    <button
                      :style="
                        sendText != '发送验证码' ? 'background: #ccc;' : ''
                      "
                      @click="sendCode"
                    >
                      {{ sendText }}
                    </button>
                  </div>
                  <div class="item">
                    <input
                      v-model="forgetParams.password"
                      placeholder="请输入登录密码"
                      :type="open1 ? 'password' : 'text'"
                    />
                    <img
                      @click="open1 = !open1"
                      v-if="open1"
                      src="@/assets/design-img/icon_14.png"
                    />
                    <img
                      @click="open1 = !open1"
                      v-if="!open1"
                      style="width: 18px; height: 13px"
                      src="@/assets/design-img/icon_15.png"
                    />
                  </div>
                  <div class="item">
                    <input
                      v-model="forgetParams.passwords"
                     
                      placeholder="请再次输入密码"
                      :type="open2 ? 'password' : 'text'"
                    />
                    <img
                      @click="open2 = !open2"
                      v-if="open2"
                      src="@/assets/design-img/icon_14.png"
                    />
                    <img
                      @click="open2 = !open2"
                      v-if="!open2"
                      style="width: 18px; height: 13px"
                      src="@/assets/design-img/icon_15.png"
                    />
                  </div>
                  <div class="item" @click="confirmClick">确认</div>
                </div>
              </div>
             
              <!-- 隐私政策 -->
              <div class="Privacy" v-if="loginAndregister == 3">
                <div class="title_box">
                  <div class="titleys">{{isPrivacy==1 ? '用户协议':'隐私政策'}}</div>
                </div>
                <img @click="close" class="yinsimg" src="@/assets/page/cuo.png" alt=""/>
                <div class="ys_content">
                  <!--  -->
                  <div class="content_text ql-snow ql-editor" v-html="agreementListdata.content">
                    
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import nologin from "@/components/Nopublic/login/Nologin.vue";
import {
  login,
  RegisterEvents,
  verificationCode,
  code,
  tuichu,
  sendForgetCode,
  forgetPassword,
  agreement,
  AgreementContent
} from "@/network/login";
export default {
  data() {
    return {
      sendText: "发送验证码",
      forgetParams: {
        phone: null,
        code: null,
        password: null,
        passwords: null,
      },
      contentWidth: "",
      SearchTasks: "", // 搜索任务

      tokens: true, // 是否已经登录

      isH: "all", // 高亮

      dialogVisible: false, // 弹出框

      loginAndregister: 2, // 登录还是注册，0是注册 1是登录

      ContainerArray: ["密码登录", "验证码登录"],

      // 本地
      isbend: null,

      // 密码登录还是验证码
      passwoedindex: 0,

      // 密码登录
      PasswordLogin: {
        // 手机号
        phone: "",
        // 密码
        password: "",
        checked: false,
      },
      // 登录成功的数据
      LoginSucceeded: {},
      // 验证码登录
      VerificationCode: {
        // 验证码中的手机号
        Verificationphone: "",
        // 验证码
        passwordphone: "",
        checked: false,
      },
      // 注册
      register: {
        // 注册昵称
        registerName: "",
        // 注册手机号
        registerPhone: "",
        // 注册验证码
        registerVerification: "",
        // 注册密码
        registerPassword: "",
        //确认密码
        Confirmpassword: "",
        checked: false,
      },
      // 登录的验证码
      loginzx: {
        sendmsg: 60,
        isname: "发送验证码",
        isSend: true,
      },
      // 注册的验证码
      establish: {
        sendmsg: 60,
        isname: "发送验证码",
        isSend: true,
      },

      // 密码显示1111
      controlEye: false,
      // 密码显示2222
      controlEye2: false,
      // 密码显示33333
      controlEye3: false,
      // 密码眼睛切换11111
      passwordEye: true,
      // 密码眼睛切换22222
      passwordEye2: true,
      // 密码眼睛切换3333
      passwordEye3: true,
      //

      open1: true,
      open2: true,

      // 判断是隐私还是协议
      isPrivacy: 0,
      // 协议数据id
      agreementData: [],
      // 详情
      agreementListdata:[]
    };
  },
  components: {
    nologin,
  },

  created() {
    //判断是否登录以及注销账户
    this.judgmentcancel();
  },
  mounted() {
    // 控制登录注册按钮
    // let jatanc = localStorage.getItem("logininfo")
    // console.log(jatanc);
    // if (jatanc != null) {
    //   // console.log(jatanc.length);
    //   if (jatanc.length == 2 ||JSON.parse(localStorage.getItem("logininfo"))=='[]') {
    //     this.tokens=false
    //     localStorage.removeItem('logininfo')
    //   }
    // }
    if (localStorage.getItem("logininfo")!=null) {
      if (!localStorage.getItem("logininfo").includes('role')) {
      this.tokens=false
      localStorage.removeItem('logininfo')
    }
    }
    
    if (localStorage.getItem("logininfo") != null) {
      this.isbend = JSON.parse(localStorage.getItem("logininfo"));
    }
  
    store.watch((state) => {
      // 路由跳转
      if (state.isLogin) {
        this.$router.push({
          path: "/",
        });
        this.tokens = false;
      }

      this.dialogVisible = state.isLogin;
      this.loginAndregister = 1;
    });

    // 获取隐私和协议
    this.deteiList()
   
  },

  methods: {
    // 获取隐私和协议
    deteiList() {
      agreement().then(res => {

        this.agreementData=res.data
        console.log('res', res);
      })
    },

    // 忘记密码 发送验证码
    sendForgetCode() {
      sendForgetCode({
        phone: this.forgetParams.phone,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          let time = 60;
          setTimeout(() => {
            if (time == 0) {
              this.sendText = "发送验证码";
            } else {
              this.sendText = time--;
            }
          }, 60);
        }
      });
    },
    // 忘记密码
    forgetPassword() {
      
      forgetPassword(this.forgetParams).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.loginAndregister = 1;
        }
      });
    },
    // 发送验证码
    sendCode() {
      if (this.forgetParams.phone == null) {
        return this.$message.warning("请输入手机号");
      }
      this.sendForgetCode();
    },
    confirmClick() {
      if (this.isObjEmpty(this.forgetParams)) {
        return this.$message.warning("请输入必填参数");
      }
      if (this.forgetParams.password != this.forgetParams.passwords) {
        return this.$message.error("两次密码输入不一致");
      }

      this.forgetPassword();
    },
    isObjEmpty(obj) {
      return Boolean(
        Object.values(obj).filter((item) => item === "" || item === null).length
      );
    },
    // 首页
    page() {
      // 路由跳转
      this.$router.push({
        path: "/",
      });
    },
    // 设计
    Design() {
      // 路由跳转
      this.$router.push({
        path: "/DesignSection",
      });
    },
    // 开发
    development() {
      // 路由跳转
      this.$router.push({
        path: "/pageDevelopment",
      });
    },
    // 资讯
    info() {
      // 路由跳转
      this.$router.push({
        path: "/Homeinformation",
      });
    },
    // 登录
    SignIn() {
      this.dialogVisible = true;
      this.loginAndregister = 1;
    }, // 点击里面注册
    addregister() {
      this.loginAndregister = 0;
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      store.state.isLogin = false;
    },
    // 忘记密码
    forgetClick() {
      this.loginAndregister = 2;
    },
    // 眼睛切换111111
    Oneye() {
      this.controlEye = !this.controlEye;
      this.passwordEye = !this.passwordEye;
    },
    // 眼睛切换222222
    Oneye2() {
      this.controlEye2 = !this.controlEye2;
      this.passwordEye2 = !this.passwordEye2;
    },
    // 眼睛切换33333
    Oneye3() {
      this.controlEye3 = !this.controlEye3;
      this.passwordEye3 = !this.passwordEye3;
    },
    // 协议
    Agreementpage(type) {
      this.agreementListdata=''
      AgreementContent(type).then(res => {
        if (res.code == 200) {
          this.isPrivacy = type
          this.loginAndregister = 3
        }
        this.agreementListdata=res.data
        // console.log(res);

      })
      // 跳转到协议弹窗
     
      // console.log(type);
      // 判断是协议还是隐私
     
    
      // this.dialogVisible = false;
      // this.$router.push({
      //   path: "/agreementInfo",
      // });
    },
    // 密码登录高亮
    addpasswoed(index) {
      this.passwoedindex = index;
    },
    //最外面的注册
    maxaddregister() {
      this.dialogVisible = !this.dialogVisible;
      this.loginAndregister = 0;
    },

    //跳转登录
    Jumplogin() {
      this.loginAndregister = 1;
    },
    // 跳转个人中心
    personal(geren) {
      this.isH = geren;
      this.$router.push({
        path: "/personalinfo",
      });
    },
    // 点击铃铛跳转
    ling() {
      // console.log(11);
      this.$router.push({
        path: "/personal",
      });
    },

    // 验证码
    send() {
      if (!this.$utils.phoneumber(this.VerificationCode.Verificationphone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
      } else {
        // 获取验证码
        verificationCode(this.VerificationCode.Verificationphone).then(
          (res) => {
            if (res.code == 200) {
              this.$message({
                message: "验证码发送成功",
                type: "success",
              });
            }
          }
        );
          
        this.loginzx.isSend = !this.loginzx.isSend;
        let yzm = setInterval(() => {
          if (this.loginzx.sendmsg > 0) {
            this.loginzx.sendmsg--;
          } else {
            clearInterval(yzm);
            this.loginzx.isSend = !this.loginzx.isSend;
            this.loginzx.isname = "重新获取";
            this.loginzx.sendmsg = 60;
          }
        }, 1000);
      }
    },

    // 获取验证码
    zcsend() {
      // 点击前判断有没有输入手机号
      if (!this.$utils.phoneumber(this.register.registerPhone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
      } else {
        // 获取验证码
        verificationCode(this.register.registerPhone).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "验证码发送成功",
              type: "success",
            });
          }
        });

        this.establish.isSend = !this.establish.isSend;
        let yzm = setInterval(() => {
          if (this.establish.sendmsg > 0) {
            this.establish.sendmsg--;
          } else {
            clearInterval(yzm);
            this.establish.isSend = !this.establish.isSend;
            this.establish.isname = "重新获取";
            this.establish.sendmsg = 60;
          }
        }, 1000);
      }
    },
    zcpassword() {
      if (this.register.registerPassword.length < 6) {
        this.$message({
          message: "密码的长度不低于6位数",
          type: "warning",
        });
      }
    },
    confirmpassword() {
      if (this.register.registerPassword != this.register.Confirmpassword) {
        this.$message({
          message: "两次密码不一致请重新输入",
          type: "warning",
        });
      
      }
    },
    //确认登录
    logOn() {
      if (this.PasswordLogin.checked) {
        login(this.PasswordLogin.phone, this.PasswordLogin.password).then(
          (res) => {
            if (res.code == 200) {
              this.$message({
                message: "登录成功",
                type: "success",
              });
              this.LoginSucceeded = res.data;
              this.$store.state.logininfo = res.data;
              this.tokens = true;
              this.dialogVisible = !this.dialogVisible;
              // 本地存储，存入用户数据
              window.localStorage.setItem(
                "logininfo",
                JSON.stringify(res.data)
              );
              // this.resetSetItem("logininfo", JSON.stringify(res.data));
            
            }
          }
        );
      } else {
        this.$message({
          message: "请阅读并勾选按钮",
          type: "warning",
        });
      }
    },

    // 注册
    tabregister() {
      if (
        this.register.registerName.length <= 0 ||
        this.register.registerName.length > 6
      ) {
        this.$message({
          message: "名称的长度不得超过6位和不得为空",
          type: "warning",
        });
        return;
      }
      if (!this.$utils.phoneumber(this.register.registerPhone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }
      RegisterEvents(
        this.register.registerName,
        this.register.registerPhone,
        this.register.registerPassword,
        this.register.Confirmpassword,
        this.register.registerVerification
      ).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "注册成功",
            type: "success",
          });
          // 跳转到登录页
          this.loginAndregister = 1;
          this.register = {
            // 注册昵称
            registerName: "",
            // 注册手机号
            registerPhone: "",
            // 注册验证码
            registerVerification: "",
            // 注册密码
            registerPassword: "",
            //确认密码
            Confirmpassword: "",
            checked: false,
          };
          this.establish = {
            sendmsg: 60,
            isname: "发送验证码",
            isSend: true,
          };
        }
      });
    },
    //退出登录
    signout() {
        this.$confirm('请问是否退出?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          lockScroll:false
        }).then(() => {
          tuichu().then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "退出成功",
            type: "success",
          });
          // 退出
          this.tokens = false;
          localStorage.removeItem("logininfo");
          //判断是否登录以及注销账户
          this.judgmentcancel();
          this.removeCookie();
        }
      });
        }).catch(() => {
                   
        });
  
   
    },

    //判断是否登录以及注销账户
    judgmentcancel() {
      if (localStorage.getItem("logininfo") != null) {
        // 已登录的状态
        this.tokens = true;
        this.LoginSucceeded = JSON.parse(localStorage.getItem("logininfo"));
      } else {
        // 未登录的状态

        this.tokens = false;
        // 重定向到首页
        this.$router.push({
          path: "/",
        });
      }
    },

    //判断验证码登录
    loginCode() {
      // 复选框勾选
      if (this.VerificationCode.checked) {
        code(
          this.VerificationCode.Verificationphone,
          this.VerificationCode.passwordphone
        ).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "登录成功",
              type: "success",
            });
            this.LoginSucceeded = res.data;
            this.tokens = true;
            this.dialogVisible = !this.dialogVisible;
            // 本地存储，存入用户数据
            window.localStorage.setItem("logininfo", JSON.stringify(res.data));
            this.isbend = res.data;
            // 渲染
          }
        });
      } else {
        this.$message({
          message: "请阅读并勾选按钮",
          type: "warning",
        });
      }
    },
    removeCookie() {
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];

        var eqPos = cookie.indexOf("=");

        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        document.cookie =
          name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }

      if (cookies.length > 0) {
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];

          var eqPos = cookie.indexOf("=");

          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

          var domain = location.host.substr(location.host.indexOf("."));

          document.cookie =
            name +
            "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
            domain;
        }
      }
    },
  },

  computed: {
    // 密码显示1111
    Judgingeyes() {
      if (this.controlEye) {
        return "text";
      } else {
        return "password";
      }
    },
    // 密码显示2222
    Judgingeyes2() {
      if (this.controlEye2) {
        return "text";
      } else {
        return "password";
      }
    },
    // 密码显示33333
    Judgingeyes3() {
      if (this.controlEye3) {
        return "text";
      } else {
        return "password";
      }
    },
    // 密码眼睛切换
    Highlight() {
      if (this.passwordEye) {
        return require("@/assets/page/yanjing_bi.png");
      } else {
        return require("@/assets/page/25982.png");
      }
    },
    // 密码眼睛切换2222222
    Highlight2() {
      if (this.passwordEye2) {
        return require("@/assets/page/yanjing_bi.png");
      } else {
        return require("@/assets/page/25982.png");
      }
    },
    // 密码眼睛切换33333
    Highlight3() {
      if (this.passwordEye3) {
        return require("@/assets/page/yanjing_bi.png");
      } else {
        return require("@/assets/page/25982.png");
      }
    },
  },
  watch: {
    // 监听高亮
    $route: {
      handler: function (val, oldval) {
        // console.log(val.name);
        if (val.name == "homepage") {
          sessionStorage.setItem("isH", "all");
          this.isH = window.sessionStorage.getItem("isH");
        } else if (val.name == "DesignSection" || val.name == "DesignDetails") {
          sessionStorage.setItem("isH", "sj");
          this.isH = window.sessionStorage.getItem("isH");
        } else if (
          val.name == "pageDevelopment" ||
          val.name == "developmentinfo"
        ) {
          sessionStorage.setItem("isH", "kf");
          this.isH = window.sessionStorage.getItem("isH");
        } else if (
          val.name == "Homeinformation" ||
          val.name == "Homeinformationitem"
        ) {
          sessionStorage.setItem("isH", "zx");
          this.isH = window.sessionStorage.getItem("isH");
        } else if (
          val.name != "homepage" &&
          val.name != "DesignSection" &&
          val.name != "DesignSection" &&
          val.name != "DesignSection" &&
          val.name != "DesignSection"
        )
        {
          sessionStorage.setItem("isH", "");
          this.isH = window.sessionStorage.getItem("isH");
        }
        //  console.log(123123132);
         let  logininfos =JSON.parse(localStorage.getItem('logininfo')) 
         if (logininfos=='[]'||logininfos==null) {
            this.tokens = false;
        }
        let jatanc = localStorage.getItem("logininfo")
        if (jatanc != null) {
          if (jatanc.length == 2) {
            this.tokens=false
            localStorage.removeItem('logininfo')
          }
        }
         
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.forgets {
  height: 532px;
  width: 470px;
  display: flex;
  flex-direction: column;
  padding: 26px 0rpx 57px 0;
  background-image: url("../../assets/page/组 3603.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  .header {
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    padding: 26px 0 18px 0;
    border-bottom: 1px solid #eee;
    position: relative;
    .f_close {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 30px;
      cursor: pointer;
    }
  }
  .contents {
    padding: 42px 44px 0 44px;
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 27px;
      cursor: pointer;
      &:last-child {
        height: 50px;
        line-height: 50px;
        background: #db585c;
        border-radius: 8px;
        margin-top: 28px;
        font-size: 20px;
        color: #fff;
        text-align: center;
        display: block;
      }
      &:nth-child(1) {
        margin-top: 0;
      }
      &:nth-child(2) {
        input {
          width: 247px;
        }
        button {
          width: 114px;
          background-color: #db585c;
          font-size: 16px;
          color: #fff;
          border-radius: 8px;
          height: 45px;
          line-height: 45px;
          text-align: center;
          cursor: pointer;
        }
      }
      input {
        border: 1px solid #eee;
        height: 50px;
        line-height: 50px;
        padding: 0 21px;
        width: 100%;
        position: relative;
        border-radius: 8px;
      }
      img {
        width: 18px;
        height: 7px;
        position: absolute;
        right: 70px;
      }
    }
  }
}
// 隐私政策弹框
.Privacy{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  border-radius:10px ;
  background: url("@/assets/page/组\ 3603.png") no-repeat center center;
  position: relative;
  overflow: hidden;
  .title_box{
    display: flex;
    height: 35px;
    font-size: 22px;
    margin-top: 20px;
    line-height: 35px;
    justify-content: center;
  }
  .yinsimg{
      width: 18px;
      height: 18px;
      position: absolute;
      top: 30px;
      right: 31px;
    }
  .ys_content{
      height: 467px;
      .content_text{
        width: 440px;
        height: 467px;
        margin: 15px auto 0;
        word-wrap: break-word;
        overflow: auto;
      }
    }
}
.topzhanwei {
  width: 100%;
  height: 90px;
}
.sum {
  width: 100%;
  height: 91px;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  border-bottom: 1px solid #eee;
}
.content {
  margin: 0 127px 0 128px;
  height: 90px;
  /* border: 1px solid #000; */
  display: flex;

  justify-content: space-between;
}
/* LOGO */
.logo_box {
  width: 700px;
  height: 89px;
  background-color: #ffffff;
  display: flex;
}
.tab_logo {
  height: 90px;
  width: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 73px;
    height: 53px;
  }
}
/* 竖杠 */
.vertical {
  height: 38px;
  margin-left: 15px;
  margin-right: 1%;
  border: 1px solid #eeeeee;
  margin-top: 30px;
}
.tab_text {
  height: 40px;
  margin-top: 20px;
  margin-left: 13px;
  color: #000;
}
/* 搜索框 */

.inp_select {
  width: 79px;
  height: 22px;
  font-size: 16px;
  margin-top: 13px;
  font-weight: bold;
  line-height: 22px;
  color: #666666;
  border: none;
  margin-left: 6px;
}
.inp_select option {
  border: none;
  appearance: none;
}
.inp_nav {
  width: 200px;
  position: relative;
}
.inp_nav img {
  /* width: 90px; */
  position: absolute;
  top: 16px;
  left: 35px;
}
.inp_nav input {
  width: 170px;
  height: 25px;
  position: absolute;
  border: none;
  top: 12px;
  left: 60px;
}
/* 右侧 */
.right_box {
  width: 590px;
  height: 89px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.Navigation {
  display: flex;
}
.Navigation li {
  width: 80px;
  height: 33px;
  font-size: 20px;

  font-weight: bold;
  color: #000;
  line-height: 95px;
  cursor: pointer;
}
/* 高亮 */
.active {
  color: #c91d1d;
  padding-bottom: 3px;
  border-bottom: 2px solid #c91d1d;
}
.login_box {
  width: 200px;
  background-color: #ffffff;
  display: flex;
}
.login_box_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 25px;
  cursor: pointer;
}
.login_box_img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.login_box_text {
  cursor: pointer;
  margin-top: 34px;
  // width: 81px;
  height: 22px;
  font-size: 16px;
  text-align: center;

  font-weight: bold;
  line-height: 22px;
  color: #000000;
  margin-left: 7%;
  margin-right: 14%;
}
.login_box_signOut {
  margin-top: 30px;
  cursor: pointer;
}
.login_box_signOut img {
  margin-top: 3px;
  width: 23px;
  height: 22px;
}
/* 登录按钮 */
.deng {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 36px;
}
.Loggedin {
  margin-top: 3px;
  min-width: 370px;
  display: flex;
}
.login_box_SignIn {
  width: 66px;
  height: 30px;
  background-color: #db585c;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
}
.login_box_register {
  width: 66px;
  height: 30px;
  background: #f9f9f9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
/* 弹窗 */
.SpringFrame {
  width: 100%;
  height: 100%;
  /* height: 567px; */
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  /* background-color: #bfa; */
}
.SpringFrame_nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  height: 567px;
  background: url("@/assets/page/组\ 3603.png") no-repeat center center;
}
.SpringFrame_shu {
  width: 2px;
  height: 12px;
  background-color: #cccccc;
}
.SpringFrame_logo {
  display: flex;
  width: 200px;
  height: 40px;
  /* background-color: #bfa; */
  position: absolute;
  top: 52px;
  left: 45px;
}
.SpringFrame_logo img {
  width: 44px;
  height: 32px;
}
.SpringFrame_shu {
  margin: 0 9px;
  margin-top: 12px;
}
.SpringFrame_text {
  width: 122px;
  height: 30px;
  font-size: 22px;

  font-weight: bold;
  line-height: 30px;
  color: #000000;
}
.delete_img {
  position: absolute;
  right: 34px;
  top: 30px;
  cursor: pointer;
}
.PasswordLogin_box {
  width: 250px;
  height: 30px;
  /* background-color: #bfa; */
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  left: 44px;
  top: 111px;
  /* background-color: #bfa; */
}
.PasswordLogin {
  width: 100px;
  height: 30px;
  font-size: 20px;

  font-weight: bold;
  line-height: 30px;
  color: #6c7482;
  cursor: pointer;
  /* position: absolute; */
}
.actives {
  color: #db585c;
}
.SpringFrame_shu2 {
  width: 2px;
  height: 20px;
  background-color: #cccccc;
  position: absolute;
  left: 111px;
  top: 7px;
}
/* 输入框 */
.inp_boxs {
  position: absolute;
  top: 176px;
  left: 44px;
  width: 382px;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
}
.inp_boxs2 {
  position: absolute;
  top: 260px;
  left: 44px;
  width: 382px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inp_sj {
  width: 224px;

  background-color: #fff;
  font-size: 16px;

  font-weight: 400;
  // margin-top: 6px;
  border: none;
  margin-left: 21px;
  color: #aaaaaa;
  display: flex;
  align-items: center;
}

.inp_sj2 {
  width: 224px;
  // height: 38px;
  border: none;
  /* line-height: 50px; */
  font-size: 16px;
  font-weight: 400;
  // margin-top: 6px;
  margin-left: 21px;
  color: #aaaaaa;
}
.password_img {
  // position: absolute;
  // right: 17px;
  // top: 18px;
  cursor: pointer;
  display: block;
  float: right;
  width: 18px;
  height: 8px;
  margin-right: 21px;
}
/* 确定按钮 */
.determine_box {
  width: 382px;
  height: 50px;
  background: #db585c;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;

  font-weight: 400;
  line-height: 28px;
  background: #db585c;
  color: #fff;
  position: absolute;
  top: 344px;
  border: none;
  left: 44px;
  cursor: pointer;
}
.register_box {
  width: 382px;
  height: 40px;
  /* background-color: #bfa; */
  position: absolute;
  top: 413px;
  left: 45px;
  display: flex;
  justify-content: space-between;
}
.register {
  width: 39px;
  height: 25px;
  font-size: 18px;

  font-weight: 400;
  line-height: 25px;
  color: #6c7482;
  line-height: 40px;
  cursor: pointer;
}
.forget {
  width: 91px;
  height: 25px;
  font-size: 18px;

  font-weight: 400;
  line-height: 25px;
  color: #6c7482;
  opacity: 1;
  line-height: 40px;
  cursor: pointer;
}
.agreement_box {
  width: 382px;
  height: 30px;
  /* background-color: #bfa; */
  position: absolute;
  top: 489px;
  left: 44px;
  display: flex;
}
.agreement_box_checkbox {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #db585c;
  margin-top: 4px;
  /* background-color: red; */
}
.agreement {
  margin-left: 20px;
  margin-top: 1px;
  font-size: 18px;
}
.xieyi {
  color: #db585c;

  // font-weight: 900;
  cursor: pointer;
  /* margin-top: 10px; */
}
/* 验证码登录 */
.Verification_box_inp {
  width: 247px;
  height: 50px;
  background: #fff;
  border: 1px solid #cccccc;
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  top: 269px;
  left: 44px;
}
.Verification_box_but {
  width: 112px;
  height: 50px;
  background: #db585c;
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  left: 270px;
  border: none;
  color: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-size: 14px;
}
.Verification_box_but2 {
  width: 112px;
  height: 50px;
  background: #ccc;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  left: 270px;
  border: none;
  color: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-size: 14px;
}
.inp_verif {
  width: 224px;
  height: 25px;
  border: none;
  font-size: 16px;

  font-weight: 400;
  margin-top: 3px;
  margin-left: 21px;
  color: #aaaaaa;
}
.inp_verif2 {
  width: 224px;
  height: 25px;
  border: none;
  font-size: 16px;

  font-weight: 400;
  margin-top: 13px;
  margin-left: 21px;
  color: #aaaaaa;
}
/* 确定按钮 */
.determine2_box {
  width: 382px;
  height: 50px;
  background: #db585c;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;

  font-weight: 400;
  line-height: 28px;
  background: #db585c;
  color: #fff;
  position: absolute;
  top: 373px;
  border: none;
  left: 44px;
  cursor: pointer;
}
/* 注册 */
.register_top {
  width: 382px;
  height: 30px;
  font-size: 22px;

  font-weight: bold;
  line-height: 30px;
  color: #333333;
  opacity: 1;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 19px;
  left: 44px;
}
.delete_img2 {
  position: absolute;
  right: 34px;
  top: 24px;
  cursor: pointer;
}
.register_shu {
  width: 470px;
  height: 1px;
  background-color: #eee;
  position: absolute;
  top: 67px;
}
.inp_boxs22 {
  position: absolute;
  top: 83px;
  left: 44px;
  width: 382px;
  height: 50px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.inp_boxs3 {
  position: absolute;
  top: 150px;
  left: 44px;
  width: 382px;
  height: 50px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.Verification_box_inp2 {
  width: 249px;
  height: 50px;
  background: #fff;
  border: 1px solid #cccccc;
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  top: 218px;
  left: 44px;
}
.registerPassword {
  position: absolute;
  top: 288px;
  left: 44px;
  width: 382px;
  border: 1px solid #cccccc;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.registerPassword2 {
  position: absolute;
  top: 359px;
  left: 44px;
  width: 382px;
  border: 1px solid #ccc;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* 确定按钮 */
.determine2_box2 {
  width: 382px;
  height: 50px;
  background: #db585c;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;

  font-weight: 400;
  line-height: 28px;
  background: #db585c;
  color: #fff;
  position: absolute;
  top: 423px;
  border: none;
  left: 44px;
  cursor: pointer;
}
.agreement_box2 {
  width: 382px;
  height: 25px;
  /* background-color: #bfa; */
  position: absolute;
  top: 493px;
  left: 44px;
  display: flex;
}
.agreement_box_checkbox2 {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #db585c;
  margin-top: 4px;
  /* background-color: red; */
}
.agreement2 {
  margin-left: 20px;
  margin-top: 1px;
  font-size: 18px;
}
.xieyi2 {
  color: #db585c;

  // font-weight: 900;
  cursor: pointer;
  /* margin-top: 10px; */
}
.have {
  width: 149px;
  height: 22px;
  font-size: 16px;

  font-weight: 400;
  line-height: 22px;
  opacity: 1;
  position: absolute;
  bottom: 10px;
  left: 172px;
}
.have span {
  color: #db585c;
  cursor: pointer;
}
/* .el-tabs__nav{
  margin-left: 30px !important;
} */

select:focus-visible {
  /* outline: -webkit-focus-ring-color auto 1px; 这是谷歌浏览器的原生样式*/
  outline: none;
}
.login_check {
  margin-left: 10px;
  margin-top: 4px;
}
.ling_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
  justify-content: center;
  cursor: pointer;
}
.ling {
  width: 23px;
  height: 24px;
  // margin-top: 26px;
}
</style>
