import axios from "axios";
import ElementUI from "element-ui";
import store from "../store/index";
// import router from "router"
// import VueRouter from "vue-router";

//页面加载进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 简单配置
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false
})


let instance = axios.create({
  baseURL: "https://wsj.nrunning.com/web/",
  timeout: 5000,
});


instance.interceptors.request.use(
  (request) => {
    // 开启进度条
    NProgress.start()

    let logininfo = JSON.parse(localStorage.getItem('logininfo'))
    if (logininfo != null) {
      request.headers.token = logininfo.token
      request.headers.phone = logininfo.phone
    }

    return request;
  },
  (err) => {}
)

instance.interceptors.response.use(
  (response) => {
    // 关闭进度条
    NProgress.done()
    let res = JSON.parse(response.data);

    if (res.code == 10001) {
      if (!store.state.isPath) {
        store.state.isLogin = true;
        
        store.state.currDbSource = [];
        ElementUI.Message({
          message: "请先进行登录",
          type: "warning",
        });
        // console.log('我即将跳转');
        // VueRouter.push({
        //   path:'/'
        // })
        // console.log('我跳转完了');
        
        return window.localStorage.clear();
      }
    }

    if (res.code == 10007 || res.code == 50000 || res.code == 10500 || res.code == 50003 || res.code == 10006|| res.code == 10004) {
      ElementUI.Message({
        message: res.message,
        type: "warning",
      });
    }

    return res;
  },
  (err) => {
    console.log(err);
  }
);

export default instance;