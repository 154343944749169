<template>
  <div>
 
  </div>
</template>
<script>
export default {
  name: 'APP',
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
  },
  created () {
  },
  mounted () {
  },
}
</script>
<style lang="scss" scoped>

</style>
