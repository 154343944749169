import request from "./index";
// 账号密码登录
export function login(phone, password) {
  return request({
    // 路径
    url: "user/login",
    // 请求方法
    method: "post",
    data: {
      phone,
      password
    }
  });
}
// 验证码登录
export function code(phone, code) {
  return request({
    // 路径
    url: "user/login",
    // 请求方法
    method: "post",
    data: {
      phone,
      code
    }
  });
}
// 注册
export function RegisterEvents(nick_name, phone, password, repassword, code) {
  return request({
    // 路径
    url: "user/register",
    // 请求方法
    method: "post",
    data: {
      nick_name,
      phone,
      password,
      repassword,
      code
    }
  });
}
// 登录协议列表
export function agreement() {
  return request({
    // 路径
    url: "pact/list",
    // 请求方法
    method: "post",
  });
}

// 登录协议内容
export function AgreementContent(id) {
  return request({
    // 路径
    url: "pact/detail",
    // 请求方法
    method: "post",
    data: {
      id
    }
  });
}

// 注册验证码获取
export function verificationCode(phone) {
  return request({
    // 路径
    url: "user/sendCode",
    // 请求方法
    method: "post",
    data: {
      phone
    }
  });
}
// 退出
export function tuichu() {
  return request({
    // 路径
    url: "user/logout",
    // 请求方法
    method: "post",
    data: {}
  });
}

// 忘记密码发送短信
export function sendForgetCode(data) {
  return request({
    // 路径
    url: "user/sendForgetCode",
    // 请求方法
    method: "post",
    data
  });
}

// 忘记密码
export function forgetPassword(data) {
  return request({
    // 路径
    url: "user/forgetPassword",
    // 请求方法
    method: "post",
    data
  });
}
// 底部信息
export function bottomdata() {
  return request({
    // 路径
    url: "site_setting/siteSetting",
    // 请求方法
    method: "post",
    
  });
}
