import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  //首页
  {
    path: "/",
    name: "homepage",
    component: () => import("../components/Nopublic/homepage/homepage.vue"),
  },
  //设计
  {
    path: "/DesignSection",
    name: "DesignSection",
    component: () =>
      import("../components/Nopublic/DesignSection/DesignSection.vue"),
  },
  //协议
  {
    path: "/agreementInfo",
    name: "agreementInfo",
    component: () =>
      import("../components/Nopublic/agreementInfo/agreementInfo.vue"),
  },
  //设计详情页面
  {
    path: "/DesignDetails",
    name: "DesignDetails",
    component: () =>
      import("../components/Nopublic/DesignSection/DesignDetails.vue"),
  },
  //开发页面
  {
    path: "/pageDevelopment",
    name: "pageDevelopment",
    component: () =>
      import("../components/Nopublic/pageDevelopment/pageDevelopment.vue"),
  },
  //开发页面详情
  {
    path: "/developmentinfo",
    name: "developmentinfo",
    component: () =>
      import("../components/Nopublic/pageDevelopment/developmentinfo.vue"),
  },
  // 资讯页面
  {
    path: "/Homeinformation",
    name: "Homeinformation",
    component: () =>
      import("../components/Nopublic/Homeinformation/Homeinformation.vue"),
  },
  // 资讯页面详情
  {
    path: "/Homeinformationitem",
    name: "Homeinformationitem",
    component: () =>
      import("../components/Nopublic/Homeinformation/Homeinformationitem.vue"),
  },
  // 个人中心
  {
    path: "/personalinfo",
    name: "personalinfo",
    component: () =>
      import("../components/Nopublic/personalinfo/personalinfo.vue"),
  },
  // 个人账户
  {
    path: "/Accountpage",
    name: "Accountpage",
    component: () =>
      import("../components/Nopublic/Accountpage/Accountpage.vue"),
  },
  // 提现记录
  {
    path: "/moneyrecord",
    name: "moneyrecord",
    component: () =>
      import("../components/Nopublic/moneyrecord/moneyrecord.vue"),
  },
  // 个人认证
  {
    path: "/dist",
    name: "dist",
    component: () => import("../components/Nopublic/distinguish/dist.vue"),
  },
  // 设计任务页面
  {
    path: "/designAssignment",
    name: "designAssignment",
    component: () =>
      import("../components/Nopublic/designAssignment/designAssignment.vue"),
  },
  // 设计任务详情页面
  {
    path: "/designtaskpage",
    name: "designtaskpage",
    component: () =>
      import("../components/Nopublic/designtaskpage/designtaskpage.vue"),
  },
  // 开发任务页面
  {
    path: "/DevelopmentTasks",
    name: "DevelopmentTasks",
    component: () =>
      import("../components/Nopublic/DevelopmentTasks/DevelopmentTasks.vue"),
  },
  // 开发任务详情页面
  {
    path: "/DetailsPage",
    name: "DetailsPage",
    component: () =>
      import("../components/Nopublic/DetailsPage/DetailsPage.vue"),
  },
  // 产品收益页面
  {
    path: "/profit",
    name: "profit",
    component: () => import("../components/Nopublic/profit/profit.vue"),
  },
  // 产品收益详情页面
  {
    path: "/ProfitInfo",
    name: "ProfitInfo",
    component: () => import("../components/Nopublic/profit/ProfitInfo.vue"),
  },
  // 个人信息页面
  {
    path: "/personal",
    name: "personal",
    component: () => import("../components/Nopublic/personal/personal.vue"),
  },
  // 帮助中心页面
  {
    path: "/HelpCenter",
    name: "HelpCenter",
    component: () => import("../components/Nopublic/HelpCenter/HelpCenter.vue"),
  },
  {
    path: "/xiaoxi",
    name: "xiaoxi",
    component: () => import("../components/Nopublic/xiaoxi/xiaoxi.vue"),
  },
  {
    path: "/bangzhu",
    name: "bangzhu",
    component: () => import("../components/Nopublic/xiaoxi/bangzhu.vue"),
  },
  {
    path: "/gongitem",
    name: "gongitem",
    component: () => import("../components/Nopublic/xiaoxi/gongitem.vue"),
  },
];

const router = new VueRouter({
  routes,
  // mode: "history",
});

// 路由跳转，回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
export default router;
