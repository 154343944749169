import request from "./index";
// 个人中心的数据
export function Personalinfo(id) {
  return request({
    // 路径
    url: "user/center",
    // 请求方法
    method: "post",
    data: {
      id
    }
  });
}
// 地区
export function AccountSettings() {
  return request({
    // 路径
    url: "user/getLocation",
    // 请求方法
    method: "post",
    data: {

    }
  });
}
//账单列表
export function bill_list(data) {
  return request({
    // 路径
    url: "user_bill/list",
    // 请求方法
    method: "post",
    data
  });
}
// 认证失败，修改
export function shejixgai(data) {
  return request({
    url: "auth_apply/update",
    // 请求方法
    method: "post",
    data
  })
}
// 更改个人信息
export function ChangeIndividual(id, nick_name, avatar, gender, phone, province, city) {
  return request({
    // 路径
    url: "user/setProfile",
    // 请求方法
    method: "post",
    data: {
      id,
      nick_name,
      avatar,
      gender,
      phone,
      province,
      city
    }
  });
}
// 个人提现
export function gitMoney(type, user_id, amount, bank_card, bank_of_deposit, bank_card_username) {
  return request({
    // 路径
    url: "cash_out/apply",
    // 请求方法
    method: "post",
    data: {
      type,
      user_id,
      amount,
      bank_card,
      bank_of_deposit,
      bank_card_username
    }
  });
}
// 公司提现
export function gitCompany(data) {
  return request({
    // 路径
    url: "cash_out/apply",
    // 请求方法
    method: "post",
    data
  });
}

// 个人主页-设计任务
export function PersonalDesign(data) {
  return request({
    // 路径
    url: "user_task/list",
    // 请求方法
    method: "post",
    data
  });
}

// 个人认证中心-复选框数据
export function checkboxData(type) {
  return request({
    url: "auth_apply/goodAtData",
    // 请求方法
    method: "post",
    data: {
      type
    }
  })
}

// 个人中心-收益详情数据
export function gitProfit(data) {
  return request({
    url: "user_profit/detail",
    // 请求方法
    method: "post",
    data
  })
}

// 个人中心-设计师个人认证
export function Personal(data) {
  return request({
    url: "auth_apply/create",
    // 请求方法
    method: "post",
    data
  })
}
// 个人中心-公司认证
export function gitenterprise(data) {
  return request({
    url: "auth_apply/create",
    // 请求方法
    method: "post",
    data
  })
}


// 个人账户的修改的内容回显
export function dataEcho(id) {
  return request({
    url: "user/getProfile",
    // 请求方法
    method: "post",
    data: {
      id
    }
  })
}


//个人中心的设计任务详情，点击投稿
export function gitContribution(task_id, remark, annex, user_id, type) {
  return request({
    url: "user_task/submission",
    // 请求方法
    method: "post",
    data: {
      task_id,
      remark,
      annex,
      user_id,
      type
    }
  })
}

// 账单提现列表
export function gitmoneylist(data) {
  return request({
    url: "cash_out/list",
    // 请求方法
    method: "post",
    data
  })
}


// 开发任务详情
export function gitdeveinfo(type, user_id, id) {
  return request({
    url: "user_task/detail",
    // 请求方法
    method: "post",
    data: {
      type,
      user_id,
      id
    }
  })
}
// 开发任务详情-盖章任务保存
export function gitPreservation(task_id, user_id, annex) {
  return request({
    url: "user_task/uploadContract",
    // 请求方法
    method: "post",
    data: {
      task_id,
      user_id,
      annex
    }
  })
}
// 开发任务详情-确认提交
export function gitSubmit(data) {
  return request({
    url: "user_task/createProgress",
    // 请求方法
    method: "post",
    data
  })
}

// 个人页面-我的信息
export function myxinxi(data) {
  return request({
    url: "user/noticeList",
    // 请求方法
    method: "post",
    data
  })
}
// 个人页面-帮助
export function mybangzhu(data) {
  return request({
    url: "help/list",
    // 请求方法
    method: "post",
    data
  })
}
// 产品收益-关联产品
export function guanlancp(data) {
  return request({
    url: "user_profit/list",
    // 请求方法
    method: "post",
    data
  })
}

// 提交稿件
export function postSubmission(data) {
  return request({
    url: 'user_task/submission',
    method: 'POST',
    data
  })
}

// 设计任务详情
export function postUserTaskList(data) {
  return request({
    url: 'user_task/detail',
    method: 'POST',
    data
  })
}

// 个人-是否认证
export function gerenrenz(data) {
  return request({
    url: 'auth_apply/getAuthInfo',
    method: 'POST',
    data
  })
}
// 消息详情
export function xiaoinfo(id) {
  return request({
    url: 'user/noticeDetail',
    method: 'POST',
    data: {
      id
    }
  })
}

// 更新用户信息
export function getinfo() {
  return request({
    url: 'user/refresh',
    method: 'POST',
    data: {

    }
  })
}

// 更新用户信息
export function jiangli(profit_id) {
  return request({
    url: 'user_profit/rewardRule',
    method: 'POST',
    data: {
      profit_id
    }
  })
}
// 帮助详情
export function banginfo(id) {
  return request({
    url: 'help/detail',
    method: 'POST',
    data: {
      id
    }
  })
}
// 公告
export function gonglist(user_id, page) {
  return request({
    url: 'user/bulletinList',
    method: 'POST',
    data: {
      user_id,
      page
    }
  })
}
// 公告
export function gitgonginfo(id) {
  return request({
    url: 'user/bulletinDetail',
    method: 'POST',
    data: {
      id
    }
  })
}
// 公告
export function zuixin_gong() {
  return request({
    url: 'bulletin/first',
    method: 'POST',
    data: {

    }
  })
}
// 权益说明
export function quanyi() {
  return request({
    url: 'user/rightRemark',
    method: 'POST',
    data: {

    }
  })
}
// 行为规范
export function xwgf() {
  return request({
    url: 'pact/xwgf',
    method: 'POST',
    data: {

    }
  })
}
// 保密承诺书
export function bmxcns() {
  return request({
    url: 'pact/bmcns',
    method: 'POST',
    data: {

    }
  })
}
// 删除开发任务的阶段
export function gitdilietkf(data) {
  return request({
    url: 'user_task/deleteProgress',
    method: 'POST',
    data
  })
}
// 确认修改密码
export function  updatePassword(data) {
  return request({
    url: 'user/updatePassword',
    method: 'POST',
    data
  })
}
// 确认修改密码
export function  pactbidRule (data) {
  return request({
    url: 'pact/bidRule',
    method: 'POST',
    data
  })
}
// 工作经验
export function  auth_applygetExperienceSetting () {
  return request({
    url: 'auth_apply/getExperienceSetting',
    method: 'POST',
    data:{}
  })
}
// 工作现状
export function auth_applygetWorkStatus() {
  return request({
    url: 'auth_apply/getWorkStatus',
    method: 'POST',
    data:{}
  })
}