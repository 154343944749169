import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import {
  getScrollbarWidth
} from '@/util/tools'

export default new Vuex.Store({
  state: {
    // 用户信息
    currDbSource: [],
    // 是否登录弹窗
    isLogin: false,

    // 是否未登陸狀態訪問
    isPath: false,
    scrollWidth: getScrollbarWidth(),
    drawer: false,
    logininfo: null,
  },
  getters: {},
  mutations: {
    saveCurrDbSource(state, currDbSource) {
      state.currDbSource = currDbSource;
    },
  },
  actions: {},
  modules: {},
});