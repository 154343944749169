import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;

import "../src/style/index.css";
import "@/assets/ico/iconfont.css";
import 'animate.css';

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI);
// 点击复制






//页面加载进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 简单配置
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false
})


// 公共方法挂载到原型上，写法为：this.$utils.hello( )
import utils from "@/util/method";
Vue.prototype.$utils = utils;

//照片路径挂载在原型上
import config from "@/util/config";
Vue.prototype.$appUrl = config.app_url;
Vue.prototype.$appUrlStorage = config.app_url_storage;

router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start()
  next()
})

router.afterEach((to, from, next) => {
  // 关闭进度条
  NProgress.done()
})



//oss配置
Vue.prototype.oss_url = 'https://dowin-wsj.oss-cn-shanghai.aliyuncs.com/';
//阿里OSSaccessKeyId
Vue.prototype.accessKeyId = 'LTAI5t9WqANs27nPJVMtoRC6';
Vue.prototype.accessKeySecret = 'YnT1LEggI7iV77uNIoMj4TqFa1ocqD';
Vue.prototype.bucket = 'dowin-wsj';
Vue.prototype.region = 'oss-cn-shanghai';

new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局事件总线
  },
  render: (h) => h(App),
}).$mount("#app");

// Vue.prototype.resetSetItem = function (key, newVal) {
//   if (key === "isH") {
//     // 创建一个StorageEvent事件
//     var newStorageEvent = document.createEvent("StorageEvent");
//     const storage = {
//       setItem: function (k, val) {
//         sessionStorage.setItem(k, val);
//         // 初始化创建的事件
//         newStorageEvent.initStorageEvent(
//           "setItem",
//           false,
//           false,
//           k,
//           null,
//           val,
//           null,
//           null
//         );
//         // 派发对象
//         window.dispatchEvent(newStorageEvent);
//       },
//     };
//     return storage.setItem(key, newVal);
//   }
// };
// Vue.prototype.resetSetItem = function (key, newVal) {
//   if (key === 'logininfo') {
//     // 创建一个StorageEvent事件
//     var newStorageEvent = document.createEvent('StorageEvent');
//     const storage = {
//       setItem: function (k, val) {
//         sessionStorage.setItem(k, val);
//         // 初始化创建的事件
//         newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
//         // 派发对象
//         window.dispatchEvent(newStorageEvent)
//       }
//     }
//     return storage.setItem(key, newVal);
//   }
// }