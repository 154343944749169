import { render, staticRenderFns } from "./Nologin.vue?vue&type=template&id=4b8086ba&scoped=true&"
import script from "./Nologin.vue?vue&type=script&lang=js&"
export * from "./Nologin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b8086ba",
  null
  
)

export default component.exports