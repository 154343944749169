<template>
  <div class="maximum">
    <div class="zhanwei"></div>
    <TabBar></TabBar>
    <!-- <sidebar></sidebar> -->
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
import TabBar from "@/components/Common/TabBar.vue";
import sidebar from "@/components/Common/sidebar.vue";
import { getinfo } from "@/network/Personal";
export default {
  components: {
    TabBar,
    sidebar,
  },
  mounted() {
    let logininfo = localStorage.getItem("logininfo");
    if (logininfo != null) {
      this.$store.state.logininfo = JSON.parse(logininfo);
    }
  },
  watch: {
    $route: {
      
      handler: function (val) {
        // console.log('我是监听器');
        if (
          val.name == "homepage" ||
          val.name == "DesignSection" ||
          val.name == "pageDevelopment" ||
          val.name == "Homeinformation"
        ) {
          this.$store.state.isPath = true;
        } else {
          this.$store.state.isPath = false;
        }

        let info = localStorage.getItem("logininfo");
        
        if (info == "[]" ) {
          //   localStorage.removeItem("logininfo")
          localStorage.clear();
          this.$router.push({
            path:'/homepage'
          })
        } else if (info != null) {
          getinfo().then((res) => {
            if (res == undefined) {
              return localStorage.clear();
            }
            this.$store.state.logininfo = JSON.stringify(res.data)
            localStorage.setItem("logininfo", JSON.stringify(res.data));
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>

.el-carousel__indicators--outside{
  display: none;
}
.el-carousel__item{
 background-color: transparent !important;
}
button {
  border: none;
  outline: none;
}
#nprogress .bar {
  background: #db585c !important;
}
.max_box .el-checkbox__inner {
  width: 19px !important;
  height: 19px !important;
}
.max_box .el-checkbox__inner::after {
  top: 3px;
  left: 6px;
}
body {
  min-height: 100.1vh;
  padding: 0;
  margin: 0;
  overflow: auto;
}
.medium {
  height: 100%;
  width: 100%;
}
.carousel__item img {
  display: block;
}
.el-tabs__nav-scroll {
  margin-top: 26px;
  margin-left: 5px;
}
.el-checkbox__label {
  font-size: 18px;
}
.name .el-cascader .el-input .el-input__inner {
  height: 50px;
  width: 599px;
}
.from_development .el-input--suffix .el-input__inner {
  padding-left: 10px;
  height: 50px;
  border: 1px solid #ccc;
}
.fananan .el-dialog__header {
  padding: 0;
}
.max_top .el-dialog__header {
  padding: 0;
}
.text .el-upload-dragger {
  line-height: 44px;
  margin-top: 10px;
  width: 160px;
  height: 44px;
  background: rgba(252, 252, 252, 0.39);
  border: 1px solid #dddddd;
  opacity: 1;
  border-radius: 5px;
}
.drawer_main_right .el-radio__input.is-checked .el-radio__inner {
  background-color: #db585c;
  border-color: #db585c;
}
.drawer_main_right .el-radio__input.is-checked + .el-radio__label {
  color: #db585c;
}

.SpringFrame .el-checkbox__inner {
  width: 22px;
  height: 21px;
}
.SpringFrame .el-checkbox__inner::after {
  top: 4px;
  left: 7px;
}
.Rotation_elm .el-carousel__mask {
  opacity: 0.3;
  position: relative;
}
.Rotation_elm .is-active {
  width: 700px !important;
  position: absolute;
  margin-left: -50px;
  z-index: 999;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset !important;
}

.el-step__head.is-process {
  color: #db585c;
}

.el-carousel {
  margin-top: 25px;
}
.el-step__title.is-process,
.el-step__title.is-wait,
.el-step__head.is-finish,
.el-step__title.is-finish {
  font-weight: 500;
}
.el-table th.el-table__cell {
  height: 100%;
  background-color: #f9f9f9;
}
.el-table th.el-table__cell > .cell {
  color: #000;
}
.el-collapse-item__header {
  padding-left: 10px;
  border: none;
}
.el-collapse {
  border: none;
}
.el-cascader .el-input .el-input__inner {
  border: 1px solid #ddd;
  padding-left: 10px;
  border-radius: 5px;
}
.el-tabs__item,
.is-top {
  font-weight: 500 !important;
}

.el-pager li {
  font-weight: 500;
}
/* 即将进行还没有进行 */
.el-step__head.is-process {
  color: #ccc;
}

/* 已完成的文字 */
.el-step__head.is-finish,
.el-step__title.is-finish {
  color: #db585c;
}
.el-carousel__mask {
  border-radius: 5px;
}
/* tab导航栏 */
.el-drawer {
  width: 750px !important;
}
.el-carousel__arrow {
  background-color: #576471;
  opacity: 0.8;
}
.is-active {
  color: #db585c !important;
}
.el-tabs__active-bar {
  background-color: #db585c !important;
}
.el-tabs__item {
  font-size: 18px;

  font-weight: bold;
  line-height: 15px;
  color: hsl(0, 0%, 0%);
  padding: 0 60px;
}
.el-tabs__item:hover {
  color: #666;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #db585c;
}
.el-pager {
  margin: 0 10px;
}
/*提现表格 */
.el-table .cell {
  text-align: center;
}

.el-step__title.is-success {
  color: #db585c;

  font-weight: 900;
  border-radius: 5px;
}
.el-step__icon-inner {
  width: 23px;
  height: 23px;
  line-height: 21px;
  border: 2px solid #db585c;
  border-radius: 50%;
  color: #db585c;
}
/* 没有走到的 */
.el-step__line-inner {
  /* line-height: 1px !important; */
  /* height: 1px !important; */
}

.el-step__line {
  height: 1px !important;
  background-color: #ccc;
}

.el-step__head.is-success {
  color: #db585c;
  border-color: #db585c;
}
.el-step__icon :before {
  font-size: 18px;
}
.icon-youjiantou_huaban:before {
  font-size: 25px;
}
.el-step__title.is-process {
  color: #ccc;
}
.el-form-item__label {
  width: 120px !important;
  text-align: left;
}
.el-form-item__label {
  line-height: 43px;
}
.el-input__inner {
  border: none;
}

.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #db585c;
  border: 1px solid #db585c;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #db585c;
}
/* 折叠 */
.el-collapse-item__header {
  width: 1146px;
  margin: 0 auto;
  background-color: #f9f9f9 !important;
  font-size: 18px;

  font-weight: bold;
  line-height: 15px;
  color: #000000;
  margin-top: 20px;
}
.el-collapse-item__content {
  position: relative;
}
.el-collapse {
  overflow: hidden;
}
.el-collapse:last-child {
  padding-bottom: 30px;
}
.el-step__head.is-finish {
  border-color: #db585c;
}
</style>
<!--  -->
